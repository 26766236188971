<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row class="hw_mb16">
                    <el-button style="margin-right:6px;" type="primary" @click.stop="batchUpdateIsRead">批量标记已读</el-button>
                    <el-button style="margin-right:24px;" type="primary" @click.stop="batchDeleteMessage">批量删除</el-button>
                </el-row>
                <el-row style="display: flex;flex-wrap: wrap;" v-show="true">
                    <div class="flex a-center" style="margin-bottom:15px;margin-right:32px;">
                        <span>通知类型：</span> 
                        <el-select class="width2"  v-model="noticeType" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="flex a-center" style="margin-bottom:15px;margin-right:32px;">
                        <span>状态：</span>
                        <el-select class="width2" v-model="isRead" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in searchTypeState" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="flex a-center" style="margin-bottom:15px;"> 
                        <el-select style="width:105px;" class="hw_mr5" v-model="searchTimeType" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in searchTimeTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker
                        v-model="searchStartTime"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                        <div class="hw_ml5 hw_mr5">-</div>
                        <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="searchEndTime"
                        placeholder="选择日期">
                        </el-date-picker>
                        <el-button style="margin-left:32px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                        <!-- <el-button @click.stop="godetail">测试</el-button> -->
                    </div>
                </el-row>
                <el-table :data="tableData"  @selection-change="handleSelectionChange"  :row-class-name="tableRowClassName" style="margin-top:0px;">
                    <el-table-column type="selection" width="55" >

                        
                    </el-table-column>
                    <el-table-column label="通知类型" prop="noticeTypeName" width="200" >
                         <template slot-scope="scope">
                            <div>{{scope.row.noticeTypeName}}</div>
                        </template>

                    </el-table-column>
                    <el-table-column label="通知内容" prop="noticeContent" >
                        <template slot-scope="scope">
                            <div :class="scope.row.isRead==1?'coloreee':'' ">{{scope.row.noticeContent}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="通知时间"  prop="noticeDatetime" width="200" ></el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'messageList',
    components: {},
    props: {},
    data() {
        return {
            testvalue:'',
            tableData: [],
            noticeType: -1,
            isRead:-1,
            searchTimeType:1,
            searchStartTime:'',
            searchEndTime:'',


            pageNum: 1,
            pageSize: 10,
            total: 0,

            searchTypeEnum: [
                { name: "全部", value: -1 },
                { name: "分公司审核通知", value: 2},
                { name: "总公司审核通知", value: 3 },
                { name: "即将清退通知", value: 1 },
                { name: "清退通知", value: 0 },
                { name: "通知翻译资料", value: 4 },
                { name: "通知打款", value: 5 },
                { name: "拆分额度通知", value: 6 },
                { name: "全套资料相关通知", value: 7 },
                { name: "转出通知", value: 8 },
                { name: "提交业绩通知", value: 9 },

            ],
            searchTypeState: [
                { name: "全部", value: -1 },
                { name: "已读", value: 1},
                { name: "未读", value: 0 },
            ],
            searchTimeTypeList: [
                { name: "通知时间", value: 1},
            ],
            multipleSelection:[],

            
        };
    },
    created() {
        if(this.$route.params.noticeType>-1){
            this.noticeType = Number(this.$route.params.noticeType) 
        }
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
         tableRowClassName({row, rowIndex}) {
            if (row.isRead) {
            return 'warning-row';
            } else if (rowIndex === 3) {
            return 'success-row';
            }
            return '';
        },
        // 批量标记已读
        async batchUpdateIsRead(){
            if(this.multipleSelection.length==0){
                this.$message({
                    message: `请勾选操作数据`,
                    type: "warning",
                });
            }else{
                const loading = this.$loading();
                let ids = []
                this.multipleSelection.forEach(element => {
                    ids.push(element.id)
                });
                const res = await this.$axios({
                    url: "/api/message/batchUpdateIsRead",
                    method: "post",
                    data: {
                        ids:ids.join()
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    this.fetchData()
                }
            }
        },
        // 批量删除
        async batchDeleteMessage(){
            if(this.multipleSelection.length==0){
                this.$message({
                    message: `请勾选操作数据`,
                    type: "warning",
                });
            }else{
               await this.$confirm(`您确定要删除吗？`, "提示")
                const loading = this.$loading();
                let ids = []
                this.multipleSelection.forEach(element => {
                    ids.push(element.id)
                });
                const res = await this.$axios({
                    url: "/api/message/batchDeleteMessage",
                    method: "post",
                    data: {
                        ids:ids.join()
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    this.fetchData()
                }
            }

        },
         handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.noticeType = -1;
            this.isRead = -1;
            this.searchTimeType=1
            this.searchStartTime=''
            this.searchEndTime=''
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            try{
                const res = await this.$axios({
                    url: "/api/message/findMessagePage",
                    method: "post",
                    data: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        noticeType:this.noticeType,
                        isRead:this.isRead,
                        searchTimeType:this.searchTimeType,
                        searchStartTime:this.searchStartTime,
                        searchEndTime:this.searchEndTime,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.data || [];
                    this.total = res.data.count || 0;
                }

            }catch(reason){
                this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});
				loading?.close();
            }

            
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
.el-table /deep/ .warning-row {
    // background: #eee !important;
  }

  .el-table .success-row {
    // background: #f0f9eb;
  }
  .coloreee{
    color: #ccc;
  }
  .nolabel /deep/ .el-form-item__content{
    // margin-left: 0!important;
  }
</style>